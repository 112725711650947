<template>
  <div class="dashboardContainer">
    <b-card>
      <b-card-header>
        <b-card-title>Accounts</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(item, accountKey) in getAccounts"
            :key="accountKey"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
          >
            <!-- :class="item.customClass" -->
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="getAccountVariant(accountKey)"
                >
                  <feather-icon
                    size="24"
                    :icon="getAccountIcon(accountKey)"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <!-- <div class="d-flex align-items-center">
                  <div> -->
                <h4 class="font-weight-bolder mb-0">
                  {{ item.count }}
                </h4>
                <b-card-text class="font-small-3 mb-0 text-capitalize">
                  {{ accountKey }} Accounts
                  <div
                    class="text-primary"
                    style="cursor: pointer;"
                    @click="onAccountViewClick(item.items, accountKey)"
                  >
                    View List
                  </div>
                </b-card-text>
                <!-- </div>
                  <feather-icon
                    class="ml-2"
                    size="15"
                    icon="ListIcon"
                  />
                </div> -->
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <div class="row second">
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header
            style="min-height: 80px;"
            class="text-center"
          >
            <b-card-title
              style="display: block;
                width: 100%;"
            >Unread Tickets</b-card-title>
          </b-card-header>

          <b-card-body>
            <div class="text-center">
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="MailIcon"
                />
              </b-avatar>
              <h4
                v-if="getTickets.new"
                class="font-weight-bolder mt-1 mb-0"
              >
                {{ getTickets.new.length }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ 'Unread Tickets' }}
              </b-card-text>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header
            style="min-height: 80px;"
            class="text-center"
          >
            <b-card-title
              style="display: block;
                width: 100%;"
            >Completed Tickets</b-card-title>
          </b-card-header>

          <b-card-body>
            <div class="text-center">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="CheckIcon"
                />
              </b-avatar>
              <h4
                v-if="getTickets.solved"
                class="font-weight-bolder mt-1 mb-0"
              >
                {{ getTickets.solved.length }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ 'Completed Tickets' }}
              </b-card-text>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header style="min-height: 80px;">
            <b-card-title>RETS Sync Records</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <b-button
                variant="flat-primary"
                :to="'/records'"
              >
                View All Records
              </b-button>
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <div class="text-center">
              <div
                v-if="Object.keys(syncData).length"
                class="row"
              >
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="syncData.listings.status === 'completed'"
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-avatar
                    v-else
                    size="48"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>{{ syncData.listings.class }}</b><br>
                    {{ new Date(syncData.listings.system_last_updated_at) }}
                  </b-card-text>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="syncData.photos.status === 'completed' && syncData.photos.id > syncData.listings.id"
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-avatar
                    v-else
                    size="48"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>{{ syncData.photos.class }}</b><br>
                    {{ new Date(syncData.photos.system_last_updated_at) }}
                  </b-card-text>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="syncData.commercialPhotos.status === 'completed'"
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-avatar
                    v-else
                    size="48"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>{{ syncData.commercialPhotos.class }}</b><br>
                    {{ new Date(syncData.commercialPhotos.system_last_updated_at) }}
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card-body>
          <!-- <b-card-body>
            <div class="text-center">
              <app-collapse v-if="Object.keys(syncData).length">
                <app-collapse-item :title="syncData.listings.value.class">
                  Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                  brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                  marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                  beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                </app-collapse-item>
                <app-collapse-item :title="syncData.photos.value.class">
                  Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                  brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                  marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                  beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                </app-collapse-item>
                <app-collapse-item :title="syncData.commercialPhotos.value.class">
                  Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                  brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                  marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                  beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                </app-collapse-item>
              </app-collapse>
            </div>
          </b-card-body> -->
        </b-card>
      </div>
      <!-- <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header style="min-height: 80px;">
            <b-card-title>Sales</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <b-dropdown
                class="text-capitalize"
                :text="getSalesDurationInText(getSales.duration)"
                variant="flat-primary"
              >
                <b-dropdown-item @click="onSalesDropdownClick(30)">
                  <span>
                    Past month
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="onSalesDropdownClick(90)">
                  <span>
                    Past 3 months
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="onSalesDropdownClick(180)">
                  <span>
                    Past 6 months
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="onSalesDropdownClick(365)">
                  <span>
                    Past year
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <div class="text-center">
              <b-avatar
                size="48"
                :variant="'light-' + getSalesVariant(getSales.change)"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
              <h4 class="font-weight-bolder mt-1 mb-0">
                <feather-icon
                  size="18"
                  :icon="getSalesIcon(getSales.change)"
                  :class="'text-' + getSalesVariant(getSales.change)"
                />
                <span :class="'text-' + getSalesVariant(getSales.change)">{{ getSales.change }}% {{ getSalesDurationInText(getSales.duration) }}</span>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ 'Sales' }}
              </b-card-text>
            </div>
          </b-card-body>
        </b-card>
      </div> -->
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header style="min-height: 80px;">
            <b-card-title>Facebook</b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="text-center">
              <div
                class="row"
              >
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.facebook.active"
                    size="48"
                    variant="light-success"
                  >
                    <span v-if="snsAccounts.facebook.active.length">
                      {{ snsAccounts.facebook.active.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Activated Tokens</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openTokens(snsAccounts.facebook.active)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.facebook.expires_soon"
                    size="48"
                    variant="light-warning"
                  >
                    <span v-if="snsAccounts.facebook.expires_soon.length">
                      {{ snsAccounts.facebook.expires_soon.length }}
                    </span>
                    <feather-icon
                      size="24"
                      icon="AlertTriangleIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Tokens About to Expire</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openTokens(snsAccounts.facebook.expires_soon)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.facebook.expired"
                    size="48"
                    variant="light-danger"
                  >
                    <span v-if="snsAccounts.facebook.expired.length">
                      {{ snsAccounts.facebook.expired.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Expired Tokens</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openTokens(snsAccounts.facebook.expired)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.facebook.listings"
                    size="48"
                    variant="light-success"
                  >
                    <span v-if="snsAccounts.facebook.listings.completed.length">
                      {{ snsAccounts.facebook.listings.completed.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Completed Listings</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openListings(snsAccounts.facebook.listings.completed)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.facebook.listings"
                    size="48"
                    variant="light-warning"
                  >
                    <span v-if="snsAccounts.facebook.listings.init.length">
                      {{ snsAccounts.facebook.listings.init.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="AlertTriangleIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Init Listings</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openListings(snsAccounts.facebook.listings.init)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.facebook.listings"
                    size="48"
                    variant="light-danger"
                  >
                    <span v-if="snsAccounts.facebook.listings.error.length">
                      {{ snsAccounts.facebook.listings.error.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Failed Listings</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openListings(snsAccounts.facebook.listings.error)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header style="min-height: 80px;">
            <b-card-title>Instagram</b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="text-center">
              <div
                class="row"
              >
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.instagram.active"
                    size="48"
                    variant="light-success"
                  >
                    <span v-if="snsAccounts.instagram.active.length">
                      {{ snsAccounts.instagram.active.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Activated Tokens</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openTokens(snsAccounts.instagram.active)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.instagram.expires_soon"
                    size="48"
                    variant="light-warning"
                  >
                    <span v-if="snsAccounts.instagram.expires_soon.length">
                      {{ snsAccounts.instagram.expires_soon.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="AlertTriangleIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Tokens About to Expire</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openTokens(snsAccounts.instagram.expires_soon)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.instagram.expired"
                    size="48"
                    variant="light-danger"
                  >
                    <span v-if="snsAccounts.instagram.expired.length">
                      {{ snsAccounts.instagram.expired.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Expired Tokens</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openTokens(snsAccounts.instagram.expired)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.instagram.listings"
                    size="48"
                    variant="light-success"
                  >
                    <span v-if="snsAccounts.instagram.listings.completed.length">
                      {{ snsAccounts.instagram.listings.completed.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Completed Listings</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openListings(snsAccounts.instagram.listings.completed)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.instagram.listings"
                    size="48"
                    variant="light-warning"
                  >
                    <span v-if="snsAccounts.instagram.listings.init.length">
                      {{ snsAccounts.instagram.listings.init.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="AlertTriangleIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Init Listings</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openListings(snsAccounts.instagram.listings.init)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <b-avatar
                    v-if="snsAccounts.instagram.listings"
                    size="48"
                    variant="light-danger"
                  >
                    <span v-if="snsAccounts.instagram.listings.error.length">
                      {{ snsAccounts.instagram.listings.error.length }}
                    </span>
                    <feather-icon
                      v-else
                      size="24"
                      icon="XIcon"
                    />
                  </b-avatar>
                  <b-card-text class="font-small-3 mt-1">
                    <b>Failed Listings</b><br>
                    <div
                      class="text-primary"
                      style="cursor: pointer;"
                      @click="openListings(snsAccounts.instagram.listings.error)"
                    >
                      View List
                    </div>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <b-card>
          <b-card-header>
            <b-card-title>New Clients</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <b-dropdown
                :text="'In ' + selectedClientYear"
                variant="flat-primary"
              >
                <b-dropdown-item
                  v-for="(year, index) in getClientsYear"
                  :key="'client-year-' + index"
                  @click="onClientsDropdownClick(year)"
                >
                  <span>Clients In {{ year }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <vue-apex-charts
              ref="clientChart"
              type="line"
              height="360"
              :options="clientChartLine.chartOptions"
              :series="clientChartLine.series"
            />
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Cities - Top 10'">
          <b-table
            v-if="Object.keys(getPopularCities).length"
            :items="getPopularCities"
            :fields="fields"
          />
          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Data
            </div>
          </b-alert>
        <!-- <AdminPopularChart :data="getPopular.cities" /> -->
        </b-card>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Sub Areas - Top 10'">
          <b-table
            v-if="Object.keys(getPopularSubArea).length"
            :items="getPopularSubArea"
            :fields="fields"
          />
          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Data
            </div>
          </b-alert>
        <!-- <AdminPopularChart :data="getPopular.subAreas" /> -->
        </b-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Agents - Top 10'">
          <b-table
            v-if="Object.keys(getPopularAgent).length"
            :items="getPopularAgent"
            :fields="fields"
          />
          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Data
            </div>
          </b-alert>
        <!-- <AdminPopularChart :data="getPopular.accounts" /> -->
        </b-card>
      </div>
      <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Property Offices - Top 10'">
          <b-table
            v-if="Object.keys(getPopularOffice).length"
            :items="getPopularOffice"
            :fields="fields"
          />
          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Data
            </div>
          </b-alert>
        <!-- <AdminPopularChart :data="getPopular.office" /> -->
        </b-card>
      </div>
    </div>
    <b-modal
      id="sns-token-list"
      centered
      size="lg"
      title="SNS Token List"
      ok-only
      ok-title="Close"
    >
      <b-table
        v-if="selectedTokens.length"
        :items="selectedTokens"
      />
      <b-alert
        v-else
        variant="warning"
        show
      >
        <div class="alert-body">
          No Tokens available
        </div>
      </b-alert>
    </b-modal>
    <b-modal
      id="sns-listing-list"
      centered
      size="xl"
      title="SNS Listings List"
      ok-only
      ok-title="Close"
    >
      <b-table
        v-if="selectedListings.length"
        :items="selectedListings"
      />
      <b-alert
        v-else
        variant="warning"
        show
      >
        <div class="alert-body">
          No Listings available
        </div>
      </b-alert>
    </b-modal>
    <b-modal
      id="modal-account-list"
      centered
      size="lg"
      title="Accounts List"
      ok-only
      ok-title="Close"
    >
      <b-tabs v-if="selectedAccontType === 'active'">
        <!-- TODO: Change selectedAccounts to selectedActiveAccounts  -->
        <b-tab
          v-for="(key, index) in Object.keys(selectedAccounts)"
          :key="index"
          :title="key.toUpperCase()"
        >
          <b-table
            v-if="selectedAccounts[key].length"
            responsive="sm"
            :items="selectedAccounts[key]"
          />
          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              No accounts available
            </div>
          </b-alert>
        </b-tab>
      </b-tabs>
      <b-table
        v-else-if="selectedAccounts.length"
        responsive="sm"
        :items="selectedAccounts"
      />
      <b-alert
        v-else
        variant="warning"
        show
      >
        <div class="alert-body">
          No accounts available
        </div>
      </b-alert>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem, BButton, BModal, BTable, BAlert, BTabs, BTab,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import AdminPopularChart from './AdminPopularChart.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
    BButton,
    BModal,
    // AdminPopularChart,
    BTable,
    BAlert,
    BTabs,
    BTab,
  },
  data() {
    return {
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      fields: [{
        key: 'key',
        label: 'Name',
      }, {
        key: 'value',
        label: '# of listings',
        sortable: true,
      }],
      ticketsData: {},
      clientData: {},
      clientYearArray: [2021],
      clientSelectedYear: new Date().getFullYear(),
      clientChartLine: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
          },
          stroke: {
            curve: 'straight',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['var(--primary)', '#ff0000'],
          fill: {
            gradient: {
              shade: 'dark',
              inverseColors: false,
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val.toFixed(0)
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      salesData: {
        duration: 0,
        change: 0,
      },
      accountsData: {},
      popularData: {},
      syncData: {},
      selectedClientYear: new Date().getFullYear(),
      currentUnreadPage: 1,
      unreadRows: 5,
      unreadPerPage: 5,
      selectedChartOption: 'In Percentage',
      selectedAccontType: 'active',
      // Mock up data
      selectedAccounts: [],
      selectedTokens: {},
      selectedListings: {},
      snsAccounts: {
        facebook: [],
        instagram: [],
      },
      selectedActiveAccount: {
        basic: [],
        premium: [],
      },
      agentChartMockup: {
        series: [{
          name: 'Sales',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 55, 78, 95],
        }],
        chartOptions: {
          chart: {
            // height: 350,
            type: 'line',
            toolbar: { show: false },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
        },
      },
    }
  },
  computed: {
    getAccounts() {
      return this.accountsData
    },
    getTickets() {
      return this.ticketsData
    },
    getClients() {
      return this.clientData
    },
    getSales() {
      return this.salesData
    },
    getPopular() {
      return this.popularData
    },
    getClientsYear() {
      return this.clientYearArray
    },
    // Objects to Array
    // Sort and return
    getPopularCities() {
      // this.predefines = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      const data = this.popularData.cities

      if (!data) {
        return []
      }

      if (data.length > 10) {
        return data.sort((a, b) => b.value - a.value).slice(0, 10)
      }
      return data.sort((a, b) => b.value - a.value)
    },
    getPopularSubArea() {
      const data = this.popularData.subAreas

      if (!data) {
        return []
      }

      if (data.length > 10) {
        return data.sort((a, b) => b.value - a.value).slice(0, 10)
      }
      return data.sort((a, b) => b.value - a.value)
    },
    getPopularAgent() {
      const data = this.popularData.accounts

      if (!data) {
        return []
      }

      if (data.length > 10) {
        return data.sort((a, b) => b.value - a.value).slice(0, 10)
      }

      return data.sort((a, b) => b.value - a.value)
    },
    getPopularOffice() {
      const data = this.popularData.office

      if (!data) {
        return []
      }

      if (data.length > 10) {
        return data.sort((a, b) => b.value - a.value).slice(0, 10)
      }
      return data.sort((a, b) => b.value - a.value)
    },
  },
  created() {
    this.$store.dispatch('superadmin/getDashBoard').then(response => {
      console.log(response)
      if (response.code === 200) {
        this.accountsData = response.data.accounts
        this.popularData = response.data.populars
        this.ticketsData = response.data.tickets
        this.syncData = response.data.syncs
        this.snsAccounts = response.data.snsAccounts
      }
    }).catch(err => {
      console.error(err)
    })

    this.$store.dispatch('superadmin/getClients', { year: new Date().getFullYear() }).then(response => {
      if (response.code === 200) {
        // this.clientData = response.data
        response.data.forEach(element => {
          const month = new Date(element.created_at).getMonth()
          const year = new Date(element.created_at).getFullYear()

          if (this.clientData[year]) {
            this.clientData[year][month]++
          } else if (year === new Date().getFullYear()) {
            const monthArr = []
            for (let i = 0; i <= new Date().getMonth(); i++) {
              monthArr.push(0)
            }

            this.clientData[year] = monthArr
            this.clientData[year][month] = 1
          } else {
            const monthArr = []
            for (let i = 0; i < 12; i++) {
              monthArr.push(0)
            }

            this.clientData[year] = monthArr
            this.clientData[year][month] = 1
          }
        })

        this.updateClientChart(this.clientSelectedYear)
        this.getClientYearArray()
      }
    }).catch(err => {
      console.error(err)
    })

    // Default: past month
    this.$store.dispatch('superadmin/getSales', { days: 30 }).then(response => {
      if (response.code === 200) {
        this.salesData.change = response.data
        this.salesData.duration = 30
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    onChartOptClick(opt) {
      this.selectedChartOption = opt
    },
    onAccountViewClick(items, key) {
      this.selectedAccounts = items
      // if (key === 'acitve') {
      //   items.forEach(account => {
      //     if(account basci) {
      //       this.selectedActiveAccount.active.push(account)
      //     } else {
      //       this.selectedActiveAccount.premium.push(account)
      //     }
      //   })
      // }
      this.selectedAccontType = key

      this.$bvModal.show('modal-account-list')
    },
    getClientYearArray() {
      this.clientYearArray = []
      for (let y = 2020; y <= new Date().getFullYear(); y++) {
        this.clientYearArray.push(y)
      }
    },
    getAccountIcon(status) {
      switch (status) {
        case 'activated':
          return 'CheckIcon'
        case 'deactivated':
          return 'SlashIcon'
        case 'failed':
          return 'XIcon'
        case 'cancelled':
          return 'XIcon'
        default: return 'checkIcon'
      }
    },
    getAccountVariant(status) {
      switch (status) {
        case 'activated':
          return 'light-success'
        case 'deactivated':
          return 'light-danger'
        case 'failed':
          return 'light-warning'
        case 'cancelled':
          return 'light-danger'
        default: return 'light-success'
      }
    },
    getSalesIcon(change) {
      if (change > 0) {
        return 'ChevronsUpIcon'
      }

      if (change < 0) {
        return 'ChevronsDownIcon'
      }

      return 'MinusIcon'
    },
    getSalesVariant(change) {
      if (change > 0) {
        return 'success'
      }

      if (change < 0) {
        return 'danger'
      }

      return 'secondary'
    },
    onClientsDropdownClick(selected) {
      if (this.clientData[selected]) {
        this.updateClientChart(selected)
        this.selectedClientYear = selected
      } else {
        this.$store.dispatch('superadmin/getClients', { year: selected }).then(response => {
          if (response.code === 200) {
          // this.clientData = response.data
            if (response.data.length) {
              this.selectedClientYear = selected
              response.data.forEach(element => {
                const month = new Date(element.created_at).getMonth()
                const year = new Date(element.created_at).getFullYear()

                if (this.clientData[year]) {
                  this.clientData[year][month]++
                } else if (year === new Date().getFullYear()) {
                  const monthArr = []
                  for (let i = 0; i <= new Date().getMonth(); i++) {
                    monthArr.push(0)
                  }

                  this.clientData[year] = monthArr
                  this.clientData[year][month] = 1
                } else {
                  const monthArr = []
                  for (let i = 0; i < 12; i++) {
                    monthArr.push(0)
                  }

                  this.clientData[year] = monthArr
                  this.clientData[year][month] = 1
                }
              })

              this.updateClientChart(selected)
            } else {
              console.log('No Clients for the selected year')
            }
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    openTokens(tokens) {
      this.selectedTokens = tokens.map(data => {
        return {
          id: data.id,
          name: data.account.name,
          type: data.type,
          expiration: data.expired_at,
        }
      })
      this.$bvModal.show('sns-token-list')
    },
    openListings(listings) {
      this.selectedListings = listings.map(data => {
        return {
          id: data.id,
          name: data.account.name,
          class: data.listing_class,
          system_id: data.listing_system_id,
          type: data.listing_type,
          status: data.status,
          sns: data.type,
        }
      })
      this.$bvModal.show('sns-listing-list')
    },
    updateClientChart(year) {
      if (!this.clientData[year]) {
        return
      }
      this.clientChartLine.series = [{
        name: 'Clients',
        data: this.clientData[year],
      }]

      const listingMonths = []
      for (let i = 0; i < 12; i++) {
        listingMonths.push(this.monthNames[i])
      }
      this.$refs.clientChart.updateOptions({
        xaxis: {
          categories: listingMonths,
        },
        yaxis: {
          tickAmount: Math.max(...this.clientData[year]),
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
            },
          },
        },
      })

      this.clientSelectedYear = year
    },
  },
}
</script>

<style lang="scss">
.dashboardContainer .dropdown-toggle {
  text-transform: capitalize;
}
</style>
