<template>
  <!-- v-if="getClient.memberType !== 'superuser'" -->
  <div>
    <div v-if="getClient.memberType !== 'superuser'">
      <b-card>
        <b-card-header>
          <b-card-title>Statistics</b-card-title>
          <b-card-text class="mr-25 mb-0">
            <b-dropdown
              :text="'Last '+statsDays+' Days'"
              variant="flat-primary"
            >
              <b-dropdown-item @click="updateStatDays(30)">
                <span>
                  Last 30 Days
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateStatDays(60)">
                <span>
                  Last 60 Days
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateStatDays(90)">
                <span>
                  Last 90 Days
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateStatDays(180)">
                <span>
                  Last 180 Days
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateStatDays(365)">
                <span>
                  Last 365 Days
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="(item, index) in statisticsItems"
              :key="index"
              md="4"
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="24"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header
          class="align-items-baseline"
        >
          <div>
            <b-card-title class="mb-25">
              Leads
            </b-card-title>
            <b-card-text
              v-if="Object.keys(leadData).length"
              class="mb-0"
            >
              <b-dropdown
                :text="selectedLeadYear + ' Total Leads: ' + (leadYearCount[selectedLeadYear] ? leadYearCount[selectedLeadYear] : 0)"
                variant="flat-primary"
              >
                <b-dropdown-item
                  v-for="(lead, year) in leadData"
                  :key="'lead'+year"
                  @click="updateLeadChart(year)"
                >
                  {{ year }} Leads
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </div>
          <b-button
            v-if="Object.keys(leadData).length"
            variant="flat-primary"
            @click="$router.push({ name: 'leads' })"
          >
            View Leads
          </b-button>
        </b-card-header>
        <b-card-body class="pb-0">
          <vue-apex-charts
            v-if="Object.keys(leadData).length"
            ref="leadChart"
            type="line"
            height="240"
            :options="leadLine.chartOptions"
            :series="leadLine.series"
          />
          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Lead Data
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header class="align-items-baseline">
          <div>
            <b-card-title class="mb-25">
              Active Listings
            </b-card-title>
            <b-dropdown
              v-if="Object.keys(listingData).length"
              :text="selectedListingYear + ' Total Listings: ' + (listingYearCount[selectedListingYear] ? listingYearCount[selectedListingYear] : 0)"
              variant="flat-primary"
            >
              <b-dropdown-item
                v-for="(listing, year) in listingData"
                :key="'listing'+year"
                @click="updateListingChart(year)"
              >
                {{ year }} Listings
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <b-dropdown
            v-if="Object.keys(listingData).length"
            text="View Listings"
            variant="flat-primary"
          >
            <b-dropdown-item to="/residential">
              <!-- <router-link> -->
              Residential
              <!-- </router-link> -->
            </b-dropdown-item>
            <b-dropdown-item to="/commercial">
              <!-- <router-link> -->
              Commercial
              <!-- </router-link> -->
            </b-dropdown-item>
            <b-dropdown-item to="/rental">
              <!-- <router-link> -->
              Rental
              <!-- </router-link> -->
            </b-dropdown-item>
          </b-dropdown>

        </b-card-header>
        <b-card-body class="pb-0">
          <vue-apex-charts
            v-if="Object.keys(listingData).length"
            ref="listingChart"
            type="line"
            height="240"
            :options="listingLine.chartOptions"
            :series="listingLine.series"
          />

          <b-alert
            v-else
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Listing Data
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <AdminDashboard />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem, BButton, BAlert,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import AdminDashboard from './components/SuperUser/AdminDashboard.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BDropdown,
    BButton,
    BDropdownItem,
    AdminDashboard,
    BAlert,
  },
  data() {
    return {
      statsDays: 30,
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      monthCategory: [],
      residentialFields: [
        'address',
        'postal_code',
        'mls_id',
        'type',
        'status',
        'list_date',
        'asking_price',
        'bedrooms',
        'bathrooms',
      ],
      commercialFields: [
        'address',
        'postal_code',
        'mls_id',
        'type',
        'status',
        'list_date',
        'transaction_type',
        'asking_price',
      ],
      reFields: [
        'address',
        'postal_code',
        'mls_id',
        'type',
        'status',
        'list_date',
        'transaction_type',
        'asking_price',
      ],
      statisticsItems: [],
      totalListingCounts: 0,
      totalLeadsCounts: 0,
      listings: [],
      selectedListingYear: new Date().getFullYear(),
      listingData: {},
      listingYearCount: {},
      selectedLeadYear: new Date().getFullYear(),
      leadData: {},
      leadYearCount: {},
      leadLine: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
          },
          stroke: {
            curve: 'straight',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2', '#ff0000'],
          fill: {
            gradient: {
              shade: 'dark',
              inverseColors: false,
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val.toFixed(0)
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      listingLine: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
          },
          stroke: {
            curve: 'straight',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: [],
          fill: {
            gradient: {
              shade: 'dark',
              inverseColors: false,
              // gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      mockup: 0,
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientInfo
    },
    getCurrentYear() {
      return new Date().getFullYear()
    },
    getCurrentYearMonth() {
      return new Date().getFullYear()
    },
    getListingSelectedYear() {
      return this.listingYearCount
    },
    getLeadTotalCount() {
      if (this.leadLine.series[0]) {
        let total = 0
        this.leadLine.series[0].data.forEach(element => {
          total += element
        })

        return total
      }

      return 0
    },
  },
  created() {
    if (this.getClient.memberType !== 'superuser') {
      this.getStatsCategory()
      this.getListings()
      this.getLeads()
      this.getStats()
    }
  },
  methods: {
    updateLeadChart(year) {
      this.leadLine.series = [{
        name: 'Leads',
        data: this.leadData[year],
      }]

      const listingMonths = []
      console.log(this.leadData)
      if (this.leadData[year]) {
        for (let i = 0; i < this.leadData[year].length; i++) {
          listingMonths.push(this.monthNames[i])
        }

        if (this.$refs.leadChart) {
          this.$refs.leadChart.updateOptions({
            xaxis: {
              categories: listingMonths,
            },
            yaxis: {
              tickAmount: Math.max(...this.leadData[year]),
              labels: {
                style: {
                  colors: '#b9b9c3',
                  fontSize: '0.857rem',
                },
                formatter(val) {
                  return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
                },
              },
            },
          })
        }

        this.selectedLeadYear = year
      }
    },
    updateListingChart(year) {
      this.selectedListingYear = year

      this.listingLine.series = []
      this.listingLine.chartOptions.colors = []

      let foundType = ''

      let maxCount = 0

      if (this.listingData[this.selectedListingYear].residential) {
        this.listingLine.series.push({
          name: 'Residential',
          data: this.listingData[this.selectedListingYear].residential,
        })

        this.listingLine.chartOptions.colors.push('#53d6d6')

        foundType = 'residential'
        maxCount = Math.max(...this.listingData[this.selectedListingYear].residential)
      }

      if (this.listingData[this.selectedListingYear].commercial) {
        this.listingLine.series.push({
          name: 'Commercial',
          data: this.listingData[this.selectedListingYear].commercial,
        })
        this.listingLine.chartOptions.colors.push('#ee61f1')
        foundType = 'commercial'
        maxCount = Math.max(...this.listingData[this.selectedListingYear].commercial) > maxCount && Math.max(...this.listingData[this.selectedListingYear].commercial)
      }

      if (this.listingData[this.selectedListingYear].rental) {
        this.listingLine.series.push({
          name: 'Rental',
          data: this.listingData[this.selectedListingYear].rental,
        })
        this.listingLine.chartOptions.colors.push('#60e671')
        foundType = 'rental'
        maxCount = Math.max(...this.listingData[this.selectedListingYear].rental) > maxCount && Math.max(...this.listingData[this.selectedListingYear].rental)
      }

      const listingMonths = []

      for (let i = 0; i < this.listingData[year][foundType].length; i++) {
        listingMonths.push(this.monthNames[i])
      }

      if (this.$refs.listingChart) {
        this.$refs.listingChart.updateOptions({
          xaxis: {
            categories: listingMonths,
          },
          yaxis: {
            tickAmount: maxCount,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
        })
      }
    },
    updateStatDays(days) {
      this.statsDays = days
      this.getStats()
    },
    getStats() {
      this.$store.dispatch('users/getGeneralStats', { date_from: this.statsDays }).then(response => {
        this.statisticsItems = [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: response.data.leads,
            subtitle: 'Leads',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'HomeIcon',
            color: 'light-info',
            title: response.data.listings.active,
            subtitle: 'Active Listings',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'HomeIcon',
            color: 'light-danger',
            title: response.data.listings.sold,
            subtitle: 'Sold Listings',
            customClass: 'mb-2 mb-sm-0',
          },
        ]
      }).catch(err => {
        console.error(err)
      })
    },
    getStatsCategory() {
      const currentMonth = new Date().getMonth()
      const listingMonths = []

      for (let i = 0; i <= currentMonth; i++) {
        listingMonths.push(this.monthNames[i])
      }
      this.monthCategory = listingMonths

      this.listingLine.chartOptions.xaxis.categories = listingMonths
      this.leadLine.chartOptions.xaxis.categories = listingMonths
    },
    getLeads() {
      this.$store.dispatch('leads/getLeadUsers').then(response => {
        console.log(response.data.data.length)
        if (response.data.data.length) {
          response.data.data.forEach(data => {
            const month = new Date(data.created_at).getMonth()
            const year = new Date(data.created_at).getFullYear()
            if (this.leadData[year]) {
              this.leadData[year][month]++
            } else if (year === new Date().getFullYear()) {
              const monthArr = []
              for (let i = 0; i <= new Date().getMonth(); i++) {
                monthArr.push(0)
              }

              this.leadData[year] = monthArr
              this.leadData[year][month] = 1
            } else {
              const monthArr = []
              for (let i = 0; i < 12; i++) {
                monthArr.push(0)
              }

              this.leadData[year] = monthArr
              this.leadData[year][month] = 1
            }

            if (this.leadYearCount[year]) {
              this.leadYearCount[year]++
            } else {
              this.leadYearCount[year] = 1
            }
          })
          this.updateLeadChart(this.selectedLeadYear)
        } else {
          const currentYear = new Date().getFullYear()
          this.leadYearCount[currentYear] = 0
        }
      }).catch(err => {
        console.error(err)
      })
    },
    getListingMonthlyCount(listings, type) {
      if (listings.length) {
        console.log(listings)
        listings.forEach(listing => {
          const date = listing.list_date.split('-')
          const year = date[0]
          let month = date[1]

          if (month.charAt(0) === '0') {
            month = month.substring(1)
          }

          if (!this.listingData[year]) {
            const monthArr = []
            // eslint-disable-next-line eqeqeq
            if (year == new Date().getFullYear()) {
              for (let i = 0; i <= new Date().getMonth(); i++) {
                monthArr.push(0)
              }
            } else {
              for (let i = 0; i < 12; i++) {
                monthArr.push(0)
              }
            }
            this.$set(this.listingData, year, {})
            this.listingData[year][type] = monthArr
            this.listingData[year][type][month - 1] = 1
          } else if (!this.listingData[year][type]) {
            const monthArr = []
            // eslint-disable-next-line eqeqeq
            if (year == new Date().getFullYear()) {
              for (let i = 0; i <= new Date().getMonth(); i++) {
                monthArr.push(0)
              }
            } else {
              for (let i = 0; i < 12; i++) {
                monthArr.push(0)
              }
            }

            this.listingData[year][type] = monthArr
            this.listingData[year][type][month - 1] = 1
          } else {
            this.listingData[year][type][month - 1]++
          }

          if (this.listingYearCount[year]) {
            this.listingYearCount[year]++
          } else {
            this.listingYearCount[year] = 1
          }
        })
      }
    },
    getListingsByMonthly(listings) {
      this.getListingMonthlyCount(listings.residential, 'residential')
      this.getListingMonthlyCount(listings.commercial, 'commercial')
      this.getListingMonthlyCount(listings.rental, 'rental')

      let maxCount = 0

      if (this.listingData[this.selectedListingYear].residential) {
        this.listingLine.series.push({
          name: 'Residential',
          data: this.listingData[this.selectedListingYear].residential,
        })
        this.listingLine.chartOptions.colors.push('#53d6d6')
        maxCount = Math.max(...this.listingData[this.selectedListingYear].residential)
      }

      if (this.listingData[this.selectedListingYear].commercial) {
        this.listingLine.series.push({
          name: 'Commercial',
          data: this.listingData[this.selectedListingYear].commercial,
        })
        this.listingLine.chartOptions.colors.push('#ee61f1')
        maxCount = Math.max(...this.listingData[this.selectedListingYear].commercial) > maxCount && Math.max(...this.listingData[this.selectedListingYear].commercial)
      }

      if (this.listingData[this.selectedListingYear].rental) {
        this.listingLine.series.push({
          name: 'Rental',
          data: this.listingData[this.selectedListingYear].rental,
        })
        this.listingLine.chartOptions.colors.push('#60e671')
        maxCount = Math.max(...this.listingData[this.selectedListingYear].rental) > maxCount && Math.max(...this.listingData[this.selectedListingYear].rental)
      }

      if (this.$refs.listingChart) {
        this.$refs.listingChart.updateOptions({
          yaxis: {
            tickAmount: maxCount,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val.toFixed(0)
              },
            },
          },
        })
      }

      console.log(this.listingData)
      console.log(this.listingYearCount)
    },
    getListingYearCount() {
      return this.listingYearCount
    },
    commafy(num) {
      const str = num.toString().split('.')
      if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
      if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ')
      }
      return str.join('.')
    },
    getListings() {
      this.$store.dispatch('listings/getAllListings', { status: ['active'], orderBy: 'date_desc' }).then(response => {
        this.listings = response.data
        console.log(this.listings)
        if (Object.keys(this.listings).length) {
          this.getListingsByMonthly(this.listings)
        }
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>
